<template>
    <modal name="profile-work-allocation" classes="work-modal" :adaptive="true" height="auto" width="60%">
        <div class="flex flex-col max-h-full h-full">
            <div class="bg-primary py-2 px-4 flex items-center justify-between">
                <h3 class="text-white-text font-semibold text-lg flex items-center gap-2">Case Allocation</h3>
                <span class="cursor-pointer text-white-text cross" @click="hideModal">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" fill="white" class="h-7 w-7">
                        <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
                    </svg>
                </span>
            </div>
            <div class="container flex-grow overflow-y-auto scroll-bar px-4 flex flex-col overflow-hidden max-h-full">
                <workAllocation :workAllocationData="workAllocationData" @selectUser="selectUser($event)" @selectTeam="selectTeam($event)" :isLoading="isLoading" />
            </div>
        </div>
    </modal>
</template>

<script>
const workAllocation = () => import("@/components/create-client/workAllocation.vue");

export default {
    name: "profile-work-allocation",
    props: {
        workAllocationData: {
            type: Object,
            default: () => {},
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    components: {
        workAllocation,
    },
    methods: {
        hideModal() {
            this.$modal.hide("profile-work-allocation");
        },
        selectUser(event) {
            this.$emit("onWorkAllocationSave", { type: "user", status: event });
        },
        selectTeam(event) {
            this.$emit("onWorkAllocationSave", { type: "team", status: event });
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .work-modal {
    border-radius: 20px !important;
}
</style>
